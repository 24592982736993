// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as RadioButton from "../../../../../styleguide/forms/RadioButton/RadioButton.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectCloudTypeRadioTileScss from "./ProjectCloudTypeRadioTile.scss";

var css = ProjectCloudTypeRadioTileScss;

function label(cloud) {
  if (cloud === "IaasServer") {
    return "Cloud IaaS - Cloud Servers";
  } else {
    return "Cloud IaaS - Cloud Storage";
  }
}

function subHeading(cloud) {
  return "Start your project for cloud Infrastructure as a Service (IaaS).";
}

function ProjectCloudTypeRadioTile(props) {
  var __className = props.className;
  var checked = props.checked;
  var cloudType = props.cloudType;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(RadioButton.make, {
                      id: label(cloudType),
                      checked: checked,
                      onChange: (function (prim) {
                          
                        })
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: label(cloudType)
                            }),
                        JsxRuntime.jsx("p", {
                              children: subHeading(cloudType)
                            })
                      ],
                      className: css.headingAndDescriptionWrapper
                    })
              ],
              className: Cx.cx([
                    css.wrapper,
                    checked ? css.borderChecked : css.borderUnchecked,
                    className
                  ]),
              onClick: props.onClick
            });
}

var make = ProjectCloudTypeRadioTile;

export {
  css ,
  label ,
  subHeading ,
  make ,
}
/* css Not a pure module */
